<template>
  <div :class="$style.tipBottom">
    <div :class="$style.tip">
      操作提示：点击已部署/已退场楼栋查看设备状态数据
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class TipBottom extends Vue {}
</script>

<style lang="less" module>
.tipBottom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.2rem;
  .tip {
    font-size: 0.14rem;
    color: #69d3ff;
    padding: 0.1rem 0.6rem;
    background-color: transparent;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(105, 211, 255, 0.2),
      rgba(105, 211, 255, 0.2),
      transparent
    );
  }
}
</style>
