<template>
  <div :class="$style.wrap">
    <a-carousel arrows :class="$style.box" ref="carouselRef">
      <div
        slot="prevArrow"
        class="custom-slick-arrow"
        :class="$style['custom-slick-arrow-left']"
      >
        <a-icon type="left-circle" />
      </div>
      <div
        slot="nextArrow"
        class="custom-slick-arrow"
        :class="$style['custom-slick-arrow-right']"
      >
        <a-icon type="right-circle" />
      </div>
      <div
        :class="$style.imageBox"
        v-for="(screenshotArr, idx) in list"
        :key="`screenshot` + idx"
      >
        <div
          :class="$style.imageItem"
          v-for="item in screenshotArr"
          :key="item.id"
        >
          <img
            v-if="item.screenshotUrl"
            :src="item.screenshotUrl"
            :class="$style.image"
          />
          <div :class="$style.imageLabel">
            <span
              v-if="item.screenshotTime"
              :class="[$style.labelTime, $style.left]"
              >{{ item.screenshotTime }}</span
            >
            <span
              v-if="item.alarmMessageType"
              :class="[$style.labelTime, $style.right]"
              >{{ actionEnum[item.alarmMessageType] }}</span
            >
          </div>
        </div>
      </div>
    </a-carousel>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { getScreenshot } from '@/services/monitor/client';
import { formatPictureUrl } from './monitor.js';
import { monitorModule } from '@/enum/store.js';

@Component()
export default class MonitorHorizontalPicture extends Vue {
  @Prop({ type: Function }) gotoCarousel;
  @monitorModule.State cameraId;
  @monitorModule.State currentShot;
  @monitorModule.Mutation updateCurrentShot;
  changeShot(val) {
    this.updateCurrentShot(val);
    this.gotoCarousel(val);
  }

  actionEnum = {
    MOTION_DETECT: '画面发生变化',
    FACE_RECOGNITION: '人脸抓拍',
    NO_HELMET: '没戴安全帽',
    MANUAL: '人工添加截图',
  };

  screenshotQuery = {
    pageNum: 0,
    pageSize: 10,
  };
  screenshotList = [];

  get list() {
    let result = [];
    const rec = [];
    let length = this.screenshotList.length;
    for (let i = 0; i < length; i++) {
      if ((i + 1) % 4 === 0) {
        result.push(this.screenshotList[i]);
        rec.push(result);
        result = [];
      } else {
        result.push(this.screenshotList[i]);
        if (length - 1 === i) {
          const abs = 4 - result.length;
          for (let j = 0; j < abs; j++) {
            result.push({
              id: j,
              screenshotUrl: '',
              screenshotTime: '',
              alarmMessageType: '',
            });
          }
          rec.push(result);
        }
      }
    }
    return rec;
  }

  get four() {
    return this.screenshotList.slice(0, 2);
  }
  /**
   * @name 获取截图列表
   */
  async getScreenshotList() {
    this.screenshotQuery.pageNum++;

    const data = await getScreenshot({
      ...this.screenshotQuery,
      cameraId: this.cameraId,
    });
    const pictures = await formatPictureUrl(data.records);
    this.screenshotList = [
      ...this.screenshotList,
      ...pictures.map(e => ({
        ...e,
        screenshotTime: dayjs(e.screenshotTime).format('MM/DD HH:mm:ss'),
      })),
    ];
    setTimeout(() => {
      this.$refs.carouselRef && this.$refs.carouselRef.goTo(0);
    }, 100);
  }

  resetMonitor() {
    this.screenshotQuery.pageNum = 0;
    this.screenshotList = [];
    this.getScreenshotList();
  }
}
</script>
<style lang="less" module>
.box {
  height: 1.6rem;
  .custom-slick-arrow-left {
    left: 0.1rem;
    z-index: 1;
  }
  .custom-slick-arrow-right {
    right: 0.1rem;
  }
}
.wrap {
  height: 1.6rem;
  .imageBox {
    display: inline-flex !important;
    .imageItem {
      flex: 1;
      height: 1.6rem;
      padding: 0.05rem 0.05rem 0 0.05rem;
      .image {
        display: block;
        width: 100%;
        height: 1.2rem;
      }
      .imageLabel {
        display: flex;
        height: 0.35rem;
        justify-content: space-between;
        align-items: center;
        .labelTime {
          flex: 1;
          font-size: 0.1rem;
        }
        .left {
          text-align: left;
        }
        .right {
          text-align: right;
        }
      }
    }
  }
  :global {
    .ant-carousel .slick-slide {
      text-align: center;
      height: 1.6rem;
      line-height: 1;
      background-color: rgba(8, 20, 47, 0.5);
      overflow: hidden;
    }

    .ant-carousel .custom-slick-arrow {
      width: 0.25rem;
      height: 0.25rem;
      font-size: 0.25rem;
      color: #fff;
      background-color: rgba(31, 45, 61, 0.11);
      opacity: 0.3;
    }
    .ant-carousel .custom-slick-arrow:before {
      display: none;
    }
    .ant-carousel .custom-slick-arrow:hover {
      opacity: 0.5;
      color: #fff !important;
    }

    .ant-carousel .slick-slide h3 {
      color: #fff;
    }
  }
}
</style>
