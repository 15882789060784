<template>
  <div :class="$style.box">
    <div :class="$style.container">
      <img :class="$style.image" src="~@/assets/images/m-3d-loading.gif" />
      <div :class="$style.horizon"></div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class loading extends Vue {}
</script>
<style lang="less" module>
/* 定义全局的变量 */
:root {
  --light-color: #292d2f;
  --dark-color: #1d8ec7;
}
.box {
  /* 弹性布局 让页面元素垂直+水平居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 让页面占浏览器可视区域的高度 */
  width: 100vw;
  height: 100vh;
  font-family: HarmonyOS;
  background-color: var(--light-color);
  position: relative;
  z-index: 99999;
}

.container {
  /* 相对定位 */
  position: relative;
  .image {
    width: 300px;
    height: 300px;
  }
}

/* 地平线 start */
.container .horizon {
  position: absolute;
  width: 100%;
  height: 115px;
  background-color: var(--light-color);
}
/* loading 字样 start */
.container .horizon::after {
  content: 'Loading...';
  position: absolute;
  top: 30px;
  width: 100%;
  text-align: center;
  font-size: 25px;
  color: var(--dark-color);
  animation: loading 2.7s linear infinite;
}
/* loading 字样 end*/

@keyframes loading {
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
</style>
