var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.$style.project},[_c('div',{class:_vm.$style.name},[_vm._v(" "+_vm._s(_vm.projectName)+" ")])]),_c('div',{class:_vm.$style.info},_vm._l((_vm.infoList),function(item,idx){return _c('div',{key:idx,class:_vm.$style.infoItem},[_c('span',[_vm._v(_vm._s(item.info))]),_c('span',[_vm._v(_vm._s(item.text))])])}),0),_c('div',{class:_vm.$style.tabList},_vm._l((_vm.titleArr),function(item,idx){return _c('div',{key:idx,class:[
        _vm.$style.tabItem,
        _vm.currentTabIndex === idx ? _vm.$style.itemActive : '' ],on:{"click":function($event){return _vm.tabClick(idx)}}},[_c('div',{staticStyle:{"margin-left":"0.1rem"}},[_vm._v(_vm._s(item))]),_c('div',{class:_vm.$style.barDiv},[_c('x-icon',{class:_vm.$style.iconStyle1,attrs:{"type":"tc-icon-status"}}),_c('div',{class:_vm.$style.bar,style:({ width: _vm.widthArr[idx] })})],1)])}),0),(_vm.currentTabIndex === 0)?_c('div',[(_vm.progressList.length > 0)?_c('div',[_c('div',{class:_vm.$style.projectProcess},_vm._l((_vm.progressList),function(item,idx){return _c('div',{key:idx,class:_vm.$style.progress},[_c('div',{class:_vm.$style.name},[_vm._v(" "+_vm._s(item.buildingName)+" ")]),_c('a-tooltip',{attrs:{"placement":"bottom","overlayClassName":"tipBack","overlayStyle":{ zIndex: '9999' }}},[_c('template',{slot:"title"},[(['WAIT_ENTER', 'DEVICE_BIND'].indexOf(item.type) > -1)?_c('div',[_c('span',[_vm._v(" 首次提升所在楼/层高："+_vm._s(((item.firstRiseFloor) + "层/" + (item.firstRiseFloorHeight) + "米"))+" ")])]):_vm._e(),(item.type === 'BUILD_LOADING')?_c('div',[_c('div',[_vm._v(" 当前层数："+_vm._s(((item.currentLayer) + "/" + (item.buildingNumber) + "层"))+" ")]),_c('div',[_vm._v(" 距离上次提升："+_vm._s(item.sinceLastPromote === -1 ? '' : item.sinceLastPromote)+"天 ")]),_c('div',[_vm._v(" 平均提升："+_vm._s(item.averagePromote === -1 ? '' : item.averagePromote)+"天/层 ")]),_c('div',[_vm._v(" 预测提升："+_vm._s(_vm.formatTime(item.expectedPromoteDate, 'YYYY/MM/DD'))+" ")])]):_vm._e(),(item.type === 'QUIT')?_c('div',[_c('div',[_vm._v(" 退场时间："+_vm._s(_vm.formatTime(item.exitDate, 'YYYY/MM/DD'))+" ")]),_c('div',[_vm._v("平均提升："+_vm._s(item.averagePromote)+"天/层")])]):_vm._e()]),_c('div',{class:_vm.$style.progressDiv},[_c('div',{class:_vm.$style.progressLeft},[_c('div',{style:({
                    width: ((item.value) + "%"),
                    height: '0.12rem',
                    backgroundColor: _vm.colorObj[item.type],
                    borderRadius: '0.04rem',
                  })})]),_c('div',{staticStyle:{"color":"#05d3ff","width":"0.3rem"}},[_vm._v(" "+_vm._s(((item.value) + "%"))+" ")])])],2)],1)}),0),_c('div',{class:_vm.$style.legend},_vm._l((_vm.typeObj),function(item,key){return _c('div',{key:key,class:_vm.$style.legendItem},[_c('div',{class:_vm.$style.legendLeft,style:({ backgroundColor: _vm.colorObj[key] })}),_c('div',{class:_vm.$style.legendRight,style:({ color: _vm.colorObj[key] })},[_vm._v(" "+_vm._s(item)+" ")])])}),0)]):_c('div',{class:[_vm.$style.baseInfo, _vm.$style.manager]},[_c('no-data-chart')],1)]):(_vm.currentTabIndex === 1)?_c('div',[(Object.keys(_vm.baseInfo).length > 0)?_c('div',{class:_vm.$style.baseInfo},[_c('a-col',{class:_vm.$style.baseTitle},_vm._l((_vm.baseInfo),function(item,key){return _c('a-col',{key:key,class:_vm.$style.left,attrs:{"span":key === 'projectLocation' ? 24 : 12}},[_c('span',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.baseInfoText[key])+"：")]),_c('span',{class:[
              _vm.$style.content,
              key === 'projectLocation' ? _vm.$style.nowrap : '' ]},[_vm._v(" "+_vm._s(item)+" ")])])}),1)],1):_c('div',{class:[_vm.$style.baseInfo, _vm.$style.manager]},[_c('no-data-chart')],1)]):_c('div',[(_vm.personList.length > 0)?_c('div',{class:[_vm.$style.baseInfo, _vm.$style.manager]},_vm._l((_vm.personList),function(item,idx){return _c('div',{key:item.pkId + '_' + idx,class:_vm.$style.head},[_c('a-avatar',{attrs:{"size":64,"src":item.avatar}}),_c('div',{class:_vm.$style.text},[_c('span',{staticClass:"TC tc-icon-dianhua"}),_c('span',[_vm._v(_vm._s(item.memberName))])]),_c('div',{class:_vm.$style.text},[_vm._v(_vm._s(item.positionName))])],1)}),0):_c('div',{class:[_vm.$style.baseInfo, _vm.$style.manager]},[_c('no-data-chart')],1)]),_c('div',{class:_vm.$style.keyWord},[_vm._v(" 项目关键时间 ")]),_c('div',{class:_vm.$style.time},[_c('ul',{class:_vm.$style.timePoint},_vm._l((_vm.timeArr),function(item,idx){return _c('li',{key:idx,class:_vm.$style.timeItem},[_c('div',{class:[
            _vm.$style.text,
            item.time !== '' ? _vm.$style.textColor : _vm.$style.empty ]},[_vm._v(" "+_vm._s(item.time || 'zan')+" ")]),_c('div',{class:[
            _vm.$style.tail,
            idx < 5 && _vm.timeArr[idx + 1] && _vm.timeArr[idx + 1].time !== ''
              ? _vm.$style.tailBorder
              : '' ]}),_c('div',{class:[_vm.$style.node, item.time !== '' ? _vm.$style.textBack : '']}),_c('div',{class:[_vm.$style.text, item.time !== '' ? _vm.$style.textColor : '']},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }