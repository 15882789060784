<template>
  <div>
    <div :class="$style.project">
      <div :class="$style.name">
        {{ projectName }}
      </div>
    </div>
    <div :class="$style.info">
      <div v-for="(item, idx) in infoList" :key="idx" :class="$style.infoItem">
        <span>{{ item.info }}</span>
        <span>{{ item.text }}</span>
      </div>
    </div>
    <div :class="$style.tabList">
      <div
        v-for="(item, idx) in titleArr"
        :key="idx"
        :class="[
          $style.tabItem,
          currentTabIndex === idx ? $style.itemActive : '',
        ]"
        @click="tabClick(idx)"
      >
        <div style="margin-left: 0.1rem">{{ item }}</div>
        <div :class="$style.barDiv">
          <x-icon :class="$style.iconStyle1" type="tc-icon-status" />
          <div :class="$style.bar" :style="{ width: widthArr[idx] }"></div>
        </div>
      </div>
    </div>
    <!--项目进度-->
    <div v-if="currentTabIndex === 0">
      <div v-if="progressList.length > 0">
        <div :class="$style.projectProcess">
          <div
            :class="$style.progress"
            v-for="(item, idx) in progressList"
            :key="idx"
          >
            <div :class="$style.name">
              {{ item.buildingName }}
            </div>
            <a-tooltip
              placement="bottom"
              overlayClassName="tipBack"
              :overlayStyle="{ zIndex: '9999' }"
            >
              <template slot="title">
                <div
                  v-if="['WAIT_ENTER', 'DEVICE_BIND'].indexOf(item.type) > -1"
                >
                  <span>
                    首次提升所在楼/层高：{{
                      `${item.firstRiseFloor}层/${item.firstRiseFloorHeight}米`
                    }}
                  </span>
                </div>
                <div v-if="item.type === 'BUILD_LOADING'">
                  <div>
                    当前层数：{{
                      `${item.currentLayer}/${item.buildingNumber}层`
                    }}
                  </div>
                  <div>
                    距离上次提升：{{
                      item.sinceLastPromote === -1 ? '' : item.sinceLastPromote
                    }}天
                  </div>
                  <div>
                    平均提升：{{
                      item.averagePromote === -1 ? '' : item.averagePromote
                    }}天/层
                  </div>
                  <div>
                    预测提升：{{
                      formatTime(item.expectedPromoteDate, 'YYYY/MM/DD')
                    }}
                  </div>
                </div>
                <div v-if="item.type === 'QUIT'">
                  <div>
                    退场时间：{{ formatTime(item.exitDate, 'YYYY/MM/DD') }}
                  </div>
                  <div>平均提升：{{ item.averagePromote }}天/层</div>
                </div>
              </template>
              <div :class="$style.progressDiv">
                <div :class="$style.progressLeft">
                  <div
                    :style="{
                      width: `${item.value}%`,
                      height: '0.12rem',
                      backgroundColor: colorObj[item.type],
                      borderRadius: '0.04rem',
                    }"
                  ></div>
                </div>
                <div style="color: #05d3ff;width:0.3rem">
                  {{ `${item.value}%` }}
                </div>
              </div>
            </a-tooltip>
          </div>
        </div>
        <div :class="$style.legend">
          <div
            :class="$style.legendItem"
            v-for="(item, key) in typeObj"
            :key="key"
          >
            <div
              :class="$style.legendLeft"
              :style="{ backgroundColor: colorObj[key] }"
            ></div>
            <div :class="$style.legendRight" :style="{ color: colorObj[key] }">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div v-else :class="[$style.baseInfo, $style.manager]">
        <no-data-chart />
      </div>
    </div>
    <!--项目基本信息-->
    <div v-else-if="currentTabIndex === 1">
      <div v-if="Object.keys(baseInfo).length > 0" :class="$style.baseInfo">
        <a-col :class="$style.baseTitle">
          <a-col
            v-for="(item, key) in baseInfo"
            :key="key"
            :span="key === 'projectLocation' ? 24 : 12"
            :class="$style.left"
          >
            <span :class="$style.name">{{ baseInfoText[key] }}：</span>
            <span
              :class="[
                $style.content,
                key === 'projectLocation' ? $style.nowrap : '',
              ]"
            >
              {{ item }}
            </span>
          </a-col>
        </a-col>
      </div>
      <div v-else :class="[$style.baseInfo, $style.manager]">
        <no-data-chart />
      </div>
    </div>
    <!--项目负责人-->
    <div v-else>
      <div
        v-if="personList.length > 0"
        :class="[$style.baseInfo, $style.manager]"
      >
        <div
          :class="$style.head"
          v-for="(item, idx) in personList"
          :key="item.pkId + '_' + idx"
        >
          <a-avatar :size="64" :src="item.avatar" />
          <div :class="$style.text">
            <span class="TC tc-icon-dianhua" />
            <span>{{ item.memberName }}</span>
          </div>
          <div :class="$style.text">{{ item.positionName }}</div>
        </div>
      </div>
      <div v-else :class="[$style.baseInfo, $style.manager]">
        <no-data-chart />
      </div>
    </div>
    <!--关键时间-->
    <div :class="$style.keyWord">
      项目关键时间
    </div>
    <div :class="$style.time">
      <ul :class="$style.timePoint">
        <li :class="$style.timeItem" v-for="(item, idx) in timeArr" :key="idx">
          <div
            :class="[
              $style.text,
              item.time !== '' ? $style.textColor : $style.empty,
            ]"
          >
            {{ item.time || 'zan' }}
          </div>
          <div
            :class="[
              $style.tail,
              idx < 5 && timeArr[idx + 1] && timeArr[idx + 1].time !== ''
                ? $style.tailBorder
                : '',
            ]"
          ></div>
          <div
            :class="[$style.node, item.time !== '' ? $style.textBack : '']"
          ></div>
          <div :class="[$style.text, item.time !== '' ? $style.textColor : '']">
            {{ item.text }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DefaultImage from '@/assets/images/defaultNone.png';
@Component({})
export default class Overview extends Vue {
  @Prop({ type: Object, default: () => {} }) projectData;

  mounted() {
    Object.keys(this.projectData).length > 0 && this.init();
  }

  infoList = [];
  widthArr = ['0.9rem', '1.3rem', '1.1rem'];
  titleArr = ['项目进度', '项目基本信息', '负责人信息'];
  currentTabIndex = 0;
  tabClick(flag) {
    this.currentTabIndex = flag;
  }
  progressList = [];
  typeObj = {
    WAIT_ENTER: '待进场',
    DEVICE_BIND: '已部署',
    BUILD_LOADING: '待绑定',
    QUIT: '已退场',
  };
  colorObj = {
    total: '#46cad3',
    WAIT_ENTER: '#f59a23',
    DEVICE_BIND: '#06ff79',
    BUILD_LOADING: '#ffff00',
    QUIT: '#aaaaaa',
  };
  timeArr = [];
  @Watch('projectData', { deep: true })
  changeData(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.init();
    }
  }
  projectName = '';
  baseInfo = {};
  baseInfoText = {
    contractorUnit: '总包方', // 总包方
    epcPrincipal: '总包负责人', // 总包负责人
    epcPhone: '总包负责人电话', // 总包人电话
    projectSerial: '项目编号', // 项目编号
    projectType: '项目类型',
    contractSerial: '合同编号', // 合同编号
    totalBuildingCount: '总楼栋数',
    totalSeatNumber: '总机位数',
    projectLocation: '项目地址',
  };
  personList = [];
  init() {
    const list = this.projectData;
    this.projectName = list.projectName;
    this.infoList = [
      {
        info: `${list.onlineDeviceCount}/${list.deviceCount}台`,
        text: '设备在线',
      },
      {
        info: `${list.weather} ${list.temperature}℃`,
        text: '当前天气',
      },
      {
        info: `${list.windPower} 级`,
        text: '当前风力',
      },
      {
        info: `${list.humidity}%`,
        text: '当前湿度',
      },
    ];
    this.progressList = [
      {
        type: 'total',
        value: list.plannedSpeed,
        buildingName: '整体进度',
      },
    ];
    list.buildingPromoteInfos.forEach(v => {
      const o = {
        ...v,
        type: v.process,
        value: v.plannedSpeed,
        buildingName: v.buildingName,
      };
      this.progressList.push(o);
    });
    this.timeArr = this.changeTime(list.criticalTime);
    const info = {
      contractorUnit: list.contractorUnit || undefined, // 总包方
      epcPrincipal: list.epcPrincipal || undefined, // 总包负责人
      epcPhone: list.epcPhone || undefined, // 总包人电话
      projectSerial: list.projectSerial || undefined, // 项目编号
      projectType: list.projectType || undefined,
      contractSerial: list.contractSerial || undefined, // 合同编号
      totalBuildingCount: list.totalBuildingCount,
      totalSeatNumber: list.totalSeatNumber,
      projectLocation: list.projectLocation || undefined,
    };
    for (const infoKey in info) {
      if (info[infoKey] === 0 || info[infoKey]) {
        this.baseInfo[infoKey] = info[infoKey];
      }
    }
    const arr = [];
    list.baseMembers.forEach(v => {
      if (v.positionName.length > 0) {
        for (let i = 0; i < v.positionName.length; i++) {
          const o = {
            ...v,
            avatar: v.avatar ? v.avatar : DefaultImage,
            positionName: v.positionName[i],
          };
          arr.push(o);
        }
      } else {
        const o = {
          ...v,
          avatar: v.avatar ? v.avatar : DefaultImage,
          positionName: '',
        };
        arr.push(o);
      }
    });
    this.personList = arr;
  }
  changeTime(timeArr) {
    if (timeArr.createDate && timeArr.enterDate) {
      let temp = '';
      if (timeArr.createDate > timeArr.enterDate) {
        temp = timeArr.createDate;
        timeArr.createDate = timeArr.enterDate;
        timeArr.enterDate = temp;
      }
    }
    return [
      {
        time: this.formatTime(timeArr.createDate),
        text: '录入系统',
      },
      {
        time: this.formatTime(timeArr.enterDate),
        text: '项目进场',
      },
      {
        time: this.formatTime(timeArr.promoteDate),
        text: '首次提升',
      },
      {
        time: this.formatTime(timeArr.firstExitDate),
        text: '首次退场',
      },
      {
        time: this.formatTime(timeArr.allExitDate),
        text: '全部退场',
      },
    ];
  }
  formatTime(str, format = 'YYYY/MM/DD HH:mm') {
    return str ? this.$moment(str).format(format) : '';
  }
}
</script>

<style lang="less" module>
.project {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  .name {
    width: 7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #05d3ff;
    font-size: 0.3rem;
    font-weight: 500;
    text-align: center;
  }
}
.info {
  display: flex;
  justify-content: center;
  color: white;
  margin: 0.35rem 0;
  .infoItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.75rem;
    margin: 0 0.1rem;
    height: 0.5rem;
    font-size: 0.16rem;
    background-image: linear-gradient(to right, #1e2640, #198ca7, #1e2640);
    box-shadow: 0.25rem 0.1rem 0.5rem #181e33 inset;
  }
}
.tabList {
  display: flex;
  margin-left: 0.8rem;
  .tabItem {
    color: #aaa;
    font-size: 0.2rem;
    cursor: pointer;
    width: 1.4rem;
    margin: 0 0.2rem;
    &:first-child {
      margin-right: 0.2rem;
      margin-left: 0;
    }
    .iconStyle1 {
      display: none;
    }
  }
  .itemActive {
    color: white;
    .barDiv {
      display: flex;
      align-items: center;
      .iconStyle1 {
        display: block;
        color: #05d3ff;
        margin-right: 0.02rem;
        font-size: 0.12rem;
      }
      .bar {
        height: 0.01rem;
        background-color: #05d3ff;
      }
    }
  }
}
.projectProcess {
  margin: 0 0.6rem 0 0.8rem;
  overflow: auto;
  height: 2.8rem;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
.baseInfo {
  margin: 0 0.6rem 0 0.8rem;
  height: 3.04rem;
  overflow: auto;
  .baseTitle {
    font-size: 0.16rem;
    margin: 0.22rem 0;
    .left {
      display: flex;
      margin: 0.1rem 0;
    }
    .name {
      color: #ddd;
    }
    .content {
      color: white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 75%;
      display: block;
    }
    .nowrap {
      width: 85%;
    }
  }
}
.manager {
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  margin-left: 0.4rem;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .head {
    width: 23%;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
      color: #05d3ff;
      font-size: 0.16rem;
      text-align: center;
    }
  }
}
.progress {
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.22rem 0;
  .name {
    color: #05d3ff;
    width: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.16rem;
  }
  .progressDiv {
    width: 6.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.16rem;
    .progressLeft {
      width: 5.8rem;
      background-color: #d7d7d7;
      border-radius: 0.04rem;
    }
  }
}
.legend {
  margin-left: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 7rem;
  height: 0.24rem;
  .legendItem {
    display: flex;
    justify-content: center;
    align-items: center;
    .legendLeft {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 0.02rem;
      margin-right: 0.05rem;
    }
    .legendRight {
      margin-right: 0.1rem;
      font-size: 0.16rem;
    }
  }
}
.keyWord {
  color: white;
  font-size: 0.2rem;
  margin: 0.2rem 0.8rem;
}
.time {
  width: 7rem;
  margin-left: 1.3rem;
  .timePoint {
    margin: 0;
    padding: 0;
    font-size: 0.14rem;
    list-style: none;
    display: flex;
    .timeItem {
      position: relative;
      width: 1.4rem;
      &:last-child {
        .tail {
          display: none;
        }
      }
      .tail {
        position: absolute;
        width: 100%;
        border-top: 0.02rem solid;
        border-color: #e4e7ed;
      }
      .node {
        position: absolute;
        background-color: #e4e7ed;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.15rem;
        width: 0.15rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .text {
      color: white;
      padding: 0.1rem 0;
      text-align: center;
      margin-left: -1.3rem;
    }
    .textColor {
      color: #05d3ff !important;
    }
    .empty {
      opacity: 0;
      cursor: default;
    }
    .textBack {
      background-color: #05d3ff !important;
    }
    .tailBorder {
      border-color: #05d3ff !important;
    }
  }
}
:global {
  .ant-tooltip-arrow {
    &::before {
      background: #2c5ca4;
      opacity: 0.82;
    }
  }
  .ant-tooltip-inner {
    background: #2c5ca4;
    opacity: 0.82;
  }
}
</style>
