<template>
  <div>
    <div :class="$style.logoDiv">
      <div :class="$style.logoName">{{ projectData.projectName }}</div>
    </div>
    <div v-if="noMonitor" :class="$style.noMonitor">
      <span
        :class="$style.outlineIcon"
        class="TC tc-icon-shexiangtoujinyong"
      ></span>
      <p>当前暂无摄像头</p>
    </div>
    <div v-if="loading && !noMonitor">
      <monitor-play :class="$style.monitorPlay" ref="monitorPlay" />
      <monitor-select
        :class="$style.monitorSelect"
        ref="monitorSelect"
        :hasEdit="false"
        :monitorList="monitorList"
        @monitor-change="monitorChange"
      />
      <monitor-picture ref="monitorPicture" />
    </div>
  </div>
</template>

<script>
import { Component, Provide, Vue, Prop, Watch } from 'vue-property-decorator';
import MonitorPlay from '../floor/monitor/monitor-play.vue';
import MonitorSelect from '../floor/monitor/monitor-horizontal-select.vue';
import MonitorPicture from '../floor/monitor/monitor-horizontal-picture.vue';
import { monitorModule } from '@/enum/store.js';
import { getCameraListByLock as getCameraList } from '@/services/monitor/client.js';

@Component({
  components: {
    MonitorPlay,
    MonitorSelect,
    MonitorPicture,
  },
})
export default class RealMonitor extends Vue {
  @Prop({ type: Object, default: () => {} }) projectData;
  @Provide('noVisitorMode') noVisitorMode = false;

  async mounted() {
    await this.fetchMonitorData();
    this.$nextTick(() => {
      this.init();
      this.$refs.monitorPicture &&
        this.$refs.monitorPicture.getScreenshotList();
    });
  }

  @Watch('projectData.cameraId')
  onBuildIdChange(val) {
    if (val) {
      this.fetchMonitorData();
    }
  }

  monitorList = [];
  noMonitor = false;
  loading = false;
  async fetchMonitorData() {
    try {
      const params = {
        projectId: this.projectData.pkProjectId,
        pageNum: 1,
        pageSize: 100,
      };

      if (this.projectData.buildId) {
        params.buildId = this.projectData.buildId;
      }
      const { records } = await getCameraList(params);
      this.monitorList = records;

      this.toggleMonitor();

      this.loading = true;

      if (records.length === 0) {
        this.noMonitor = true;
      }
    } catch (error) {
      this.loading = true;
      this.$message.error(error.message);
    }
  }

  /** @description 摄像头切换 */
  toggleMonitor() {
    if (this.projectData.cameraId) {
      const monitor = this.monitorList.find(
        v => v.pkCameraId === this.projectData.cameraId,
      );
      if (monitor) {
        this.$nextTick(() => {
          this.updateCameraId(monitor.pkCameraId);
          this.$refs.monitorSelect &&
            this.$refs.monitorSelect.toMonitor(monitor);
        });
      }
    }
  }

  @monitorModule.Mutation updateCameraId;
  init() {
    const toMonitor =
      this.$refs.monitorSelect && this.$refs.monitorSelect.toMonitor;
    const onlineExist = this.monitorList.find(e => e.online);
    if (onlineExist) {
      this.updateCameraId(onlineExist.pkCameraId);
      toMonitor(onlineExist);
    } else {
      if (this.monitorList.length > 0) {
        toMonitor(this.monitorList[0]);
      }
    }
  }

  monitorChange() {
    this.$refs.monitorPicture.resetMonitor();
  }
}
</script>

<style lang="less" module>
.logoDiv {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logoName {
    width: 7rem;
    padding-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #05d3ff;
    font-size: 0.3rem;
    font-weight: 500;
    text-align: center;
  }
}
.noMonitor {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  .outlineIcon {
    margin-top: 0.62rem;
    font-size: 2rem;
    color: #073b6a;
  }
  p {
    margin-top: 0.12rem;
    font-size: 0.14rem;
    color: #096c9a;
  }
}
.monitorSelect {
  /* 滚动条 */
  &::-webkit-scrollbar {
    width: 0rem !important;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
}
.monitorPlay {
  margin-top: 0.4rem;
  margin-right: 0;
  margin-left: 0;
  height: 5rem;
  background-color: rgba(8, 20, 47, 0.5);
}
</style>
