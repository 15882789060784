<template>
  <div :class="$style.wrap">
    <loading v-if="loadingFlag" />
    <div v-show="!loadingFlag" :class="$style.three" id="building"></div>
    <div :class="$style.labels" id="labels" @click.stop="handleLabel"></div>
    <div :class="[$style.draw, openFlag ? $style.drawOpen : '']" @click.stop>
      <div :class="$style.outIcon" @click.stop="openFlag = !openFlag">
        <a-icon
          :class="[$style.iconStyle, openFlag ? $style.rightIcon : '']"
          type="left"
        />
      </div>
      <component
        :is="currentComponent"
        :projectData="projectData"
        :buildingInfo="buildingInfo"
        :buildingID="selectBuildId"
        @cnm="clockChange"
      />
    </div>
    <div v-if="!inClimb" :class="$style.btnList">
      <div
        v-for="(item, idx) in btnList"
        :key="idx"
        @click.stop="btnClick(idx)"
        :class="[
          $style.btnItem,
          currentIndex === idx ? $style['btnItem-active'] : '',
        ]"
      >
        <div :class="$style.btnText">
          <x-icon :class="$style.iconStyle" :type="item.icon" />
          <div>
            {{ item.text }}
          </div>
        </div>
        <div :class="[$style.btnText, $style.barHeight]">
          <x-icon :class="$style.iconStyle1" type="tc-icon-status" />
          <div :class="$style.bar"></div>
        </div>
      </div>
    </div>
    <div v-if="inClimb" :class="$style.arrowBox">
      <!-- <span :class="$style.arrowBoxLabel" @click.stop="arrowLeft">左转</span>
      <span :class="$style.arrowBoxLabel" @click.stop="arrowRight">右转</span> -->
      <span :class="$style.arrowBoxLabel" @click.stop="returnBack"
        >回到原点</span
      >
      <span :class="$style.arrowBoxLabel" @click.stop="returnClimb"
        >回到爬架</span
      >
      <span :class="$style.arrowBoxLabel" @click.stop="returnCamera"
        >查看摄像头</span
      >
    </div>
    <tip-bottom v-if="selectedDevice && !inClimb" />
    <rect-view
      v-if="inClimb"
      :climbArray="climbArray"
      :deg="deg"
      :current="climbPos"
      :pointArray="electricPointArray"
      :toggleClimb="toggleClimb"
    />
    <main-view
      v-if="selectBoxFlag"
      :detail="boxData"
      @back="mainBack"
      @status-click="showMainEleStatus"
    />
    <child-view
      v-if="selectChildBoxFlag"
      :detail="childBoxData"
      @back="resetChildEleFlagFalse"
      @status-click="showChildEleStatus"
    />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Overview from './over-view';
import OperationRecord from './operation-record';
import RealMonitor from './real-monitor';
import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import { flexDpr } from '@/assets/js/flex-dpr.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module';
import { getProjectData } from '@/services/things/building';
import Loading from './loading.vue';
import {
  animateCamera,
  labelEl,
  raycasterFactory,
  load,
  alertEle,
  getBoxDistribution,
  getMessage,
  padZero,
} from './utils';
import {
  getBuildingInfo,
  getMainBoxStatus,
  getSubBoxStatus,
  getMainBoxDistribution,
} from '@/services/things/building';
import { getCameraList } from '@/services/monitor/client.js';
import RectView from './components/rect-view.vue';
import MainView from './components/main-view.vue';
import ChildView from './components/child-view.vue';
import TipBottom from './components/tip-bottom.vue';

flexDpr();

/** @name 主控箱label */
const LABEL_EBOX = 'ebox';
/** @name 楼栋label */
const LABEL_V587 = 'v587';

@Component({
  components: {
    Overview,
    OperationRecord,
    RealMonitor,
    Loading,
    RectView,
    MainView,
    ChildView,
    TipBottom,
  },
})
export default class BuildingModel extends Vue {
  openFlag = false;
  currentComponent = 'Overview';
  currentIndex = 0;
  btnList = [
    {
      text: '项目概览',
      component: 'Overview',
      icon: 'tc-icon-company',
    },
    {
      text: '设备状态',
      component: 'DeviceStatus',
      icon: 'tc-icon-electric-box',
    },
    {
      text: '操作记录',
      component: 'OperationRecord',
      icon: 'tc-icon-order',
    },
    {
      text: '实时监控',
      component: 'RealMonitor',
      icon: 'tc-icon-video-camera',
    },
  ];
  /** @name 设备状态 */
  selectedDevice = false;
  btnClick(idx) {
    if (idx === 1) {
      this.currentIndex = idx;
      this.selectedDevice = true;
      this.openFlag = false;
      return false;
    }
    this.selectedDevice = false;
    if (idx !== 2) {
      this.clearTime();
    }
    if (idx === this.currentIndex) {
      this.openFlag = !this.openFlag;
      this.currentIndex = idx;
      this.currentComponent = this.btnList[idx].component;
    } else {
      this.openFlag = true;
      this.currentIndex = idx;
      this.currentComponent = this.btnList[idx].component;
    }
  }

  async mounted() {
    load.setHost(await load.getOssHost());
    this.init();
    this.fetchModalData();
  }
  beforeDestroy() {
    this.clearTime();
  }
  /** @type { import('three').Scene }*/
  scence = undefined;
  camera = undefined;
  renderer = undefined;
  controls = undefined;
  status = undefined;
  labelRanderer = undefined;
  labelsElement = undefined;
  renderElement = undefined;
  isDev = false;
  async init() {
    // 初始化场景
    this.scence = new THREE.Scene();

    // 初始化摄像头
    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      300,
    );
    this.camera.position.set(14, 22, 64);
    // 初始化渲染器
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    const container = document.querySelector('#building');
    container.appendChild(this.renderer.domElement);

    this.labelsElement = document.querySelector('#labels');
    labelEl.setParent(this.labelsElement);

    // 初始化控制器
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enablePan = false;
    // this.controls.minDistance = 30;
    // this.controls.maxDistance = 60;
    this.controls.screenSpacePanning = false;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.minPolarAngle = 1;
    this.controls.target = new THREE.Vector3(0, 20, 0);

    this.controls.setAngle = (phi, theta, distance) => {
      let r =
        distance ||
        this.controls.object.position.distanceTo(this.controls.target);

      let x =
        r * Math.cos(phi - Math.PI / 2) * Math.sin(theta) +
        this.controls.target.x;
      let y = r * Math.sin(phi + Math.PI / 2) + this.controls.target.y;
      let z =
        r * Math.cos(phi - Math.PI / 2) * Math.cos(theta) +
        this.controls.target.z;

      const tar = this.controls.target;
      animateCamera(
        this.camera,
        this.controls,
        { x, y, z },
        { x: tar.x, y: tar.y, z: tar.z },
        1200,
        () => {
          this.controls.object.lookAt(this.controls.target);
        },
      );
    };

    if (this.isDev) {
      // 状态监测
      this.status = new Stats();
      container.appendChild(this.status.dom);
    }
    // 加载地面
    const ground = await load.loadModel('xx-ground.glb');
    this.scence.add(ground);
    await this.initBuildings();
    this.addEvent();
    // 渲染
    this.animate();
  }
  addEvent() {
    document.addEventListener('click', this.onClickBuilding);
    window.addEventListener('resize', this.onWindowResize);
  }

  buildingInfo = [];
  position = [
    [0, 0],
    [-10, -2],
    [13, -1],
    [8, -5],
    [-7, -7],
    [0, -12],
    [-7, -14],
    [8, -14],
    [-14, -8],
    [-16, -14],
    [16, -14],
    [-12, 5],
    [11, 6],
    [-4, -6],
  ];
  climbModel;
  /** @name 隐藏爬架 */
  resetClimb() {
    this.climbModel.visible = false;
    this.climbModel.position.set(0, 0, -20);
  }
  /** @name 显示爬架 */
  ClimbVisable(item) {
    this.climbModel.visible = true;
    this.climbModel.position.set(item.x, item.y, item.z);
  }
  gridModel;
  /** @name 隐藏网格模型 */
  resetGrid() {
    this.gridModel.visible = false;
    this.gridModel.position.set(0, 0, -20);
  }
  /** @name 显示网格模型 */
  GridVisable(item) {
    this.gridModel.visible = true;
    this.gridModel.position.set(item.x - 0.01, item.y + 0.03, item.z);
  }
  cameraModel;
  eleChild;
  eleMain;
  loadingFlag = true;
  async fetchModalData() {
    // 爬架模型
    const climb = await load.loadModel(
      'climb.glb',
      g => {
        return g;
      },
      xhr => {
        if (xhr.loaded / xhr.total === 1) {
          setTimeout(() => {
            this.loadingFlag = false;
          }, 2000);
        }
      },
    );
    this.climbModel = climb.clone();
    this.resetClimb();
    this.scence.add(this.climbModel);

    // 爬架外层网格模型
    const grid = await load.loadModel('grid.glb');
    this.gridModel = grid.clone();
    this.resetGrid();
    this.scence.add(this.gridModel);

    // 加载子控箱模型
    const eleChild = await load.loadModel('small-device-t1.glb', g => {
      const group = new THREE.Group();
      g.children[0].children[0].children.forEach(v => {
        group.add(v.clone());
      });
      return group;
    });
    this.eleChild = eleChild.clone();

    // 加载主控箱模型
    const eleMain = await load.loadModel('big-device-t1.glb', g => {
      const group = new THREE.Group();
      g.children[0].children[0].children.forEach(v => {
        group.add(v.clone());
      });
      return group;
    });
    this.eleMain = eleMain.clone();

    // 摄像头模型
    const camera = await load.loadModel('camera.glb', gltf => {
      const group = new THREE.Group();
      gltf.children[0].children.forEach(item => {
        group.add(item);
      });
      return group;
    });
    this.cameraModel = camera.clone();
  }
  climbArray = [];
  layerModel = null;
  layerMesh;
  climbMesh;
  layerColorMesh = null;
  async floorModel() {
    // 加载楼栋模型
    this.layerModel = await load.loadModel('layer-t2.glb', g => {
      const group = new THREE.Group();
      g.children[0].children.forEach(item => {
        group.add(item.clone());
      });
      const m = new THREE.MeshLambertMaterial({
        color: '#fff',
        emissive: '#fff',
      });
      m.transparent = true;
      m.opacity = 0.85;
      group.children[0].material = m;
      return group;
    });
    this.layerColorMesh = this.layerModel.clone();
    const material = new THREE.MeshLambertMaterial({
      color: '#fff',
      // emissive: '#2e92d5',
      emissive: '#0161ff',
    });
    material.transparent = true;
    this.layerColorMesh.children[0].material = material;
    this.layerColorMesh.name = 'colorLayer';
    this.layerColorMesh.scale.set(1.1, 1, 1.1);
    this.resetColorLayer();
    this.scence.add(this.layerColorMesh);
  }
  resetColorLayer() {
    this.layerColorMesh.visible = false;
    this.layerColorMesh.position.set(0, 0, -20);
  }

  projectData = {};
  async initBuildings() {
    const [res] = await Promise.all([
      getProjectData(this.$route.params.projectId),
      this.floorModel(),
    ]);
    setTimeout(() => {
      this.openFlag = true;
    }, 1000);
    this.projectData = Object.assign({}, res, {
      buildId: '',
      cameraId: '',
    });
    const builds = res.buildingPromoteInfos;
    this.buildingInfo = builds;
    const layerArray = [];
    const climbArray = [];
    const elementArray = [];

    for (let i = 0; i < builds.length; i++) {
      const item = builds[i];
      const layerHeight = item.currentLayer * 0.6;
      const x = this.position[i][0];
      const z = this.position[i][1];
      for (let j = 1; j < item.currentLayer + 1; j++) {
        layerArray.push({
          name: `group${i + 1}-layer-${j}`,
          x,
          y: j * 0.6,
          z,
          group: i + 1,
          layer: j,
          buildId: item.pkBuildingId,
        });
      }

      /** @name 已部署(DEVICE_BIND)和已退场(QUIT)的楼栋，才有爬架 */
      if (item.process === 'DEVICE_BIND' || item.process === 'QUIT') {
        climbArray.push({
          name: 'climb',
          x,
          y: layerHeight + 0.63,
          z,
          buildName: item.buildingName,
          buildId: item.pkBuildingId,
          floor: item.firstRiseFloor,
          floorHeight: item.firstRiseFloorHeight,
        });
      }

      elementArray.push({
        name: `label${i}`,
        elem: alertEle.build(item, this.$style),
        position: {
          x,
          y: layerHeight + 2.63,
          z,
        },
      });
    }
    labelEl.addArray(elementArray);

    this.layerMesh = load.floor(this.layerModel, layerArray);
    this.layerMesh.name = 'floor';
    this.layerMesh.userData.array = layerArray;
    this.scence.add(this.layerMesh);
    this.formatArray(layerArray[0].buildId);

    const climbLight = new THREE.DirectionalLight(0xffffff);
    climbLight.position.set(0, 30, 0);
    this.scence.add(climbLight);

    this.climbArray = climbArray;
    this.climbMesh = load.climb(climbArray);
    this.climbMesh.name = 'climb';
    this.scence.add(this.climbMesh);
  }

  electricModelArray = [];
  electricPointArray = [];
  mainEle = {};
  createElectric(item = { x: 0, y: 0, z: 0 }, mainLength = 0, childLength = 0) {
    // 爬架间切换，电箱重复的问题
    if (this.electricModelArray.length > 0) {
      this.electricModelArray.forEach(v => {
        this.scence.remove(v);
      });

      this.electricModelArray = [];
      this.electricPointArray = [];
    }

    const { electricList, points, mainEle } = load.electric(
      item,
      mainLength,
      childLength,
      this.eleMain,
      this.eleChild,
    );
    this.mainEle = mainEle;

    electricList.forEach(v => {
      this.scence.add(v);
    });
    this.electricPointArray = points;
    this.electricModelArray = electricList;
  }

  inClimb = false;
  async onClickCameraAndBox(event) {
    const intersects = raycasterFactory(event, this.camera).intersectObject(
      this.scence,
      true,
    );

    const selected = intersects[0].object;
    // 摄像头
    if (selected.parent.name === 'camera') {
      /** @name 摄像头 */
      this.clickCamera(selected);
    } else if (
      selected.parent.name === 'electric-box' ||
      selected.parent.name === 'electric-child'
    ) {
      /** @name 主控箱和分控箱 */
      this.clickElectricBox(selected);
    }
  }
  /** @name 摄像头点击事件 */
  clickCamera(selected) {
    const userData = selected.parent.userData;
    this.projectData.buildId = userData.buildId;
    this.projectData.cameraId = userData.cameraId;

    this.openFlag = true;
    this.currentIndex = 3;
    this.currentComponent = this.btnList[3].component;
  }

  /** @name 删除电箱弹窗和设置labelCount为0 */
  resetLabelBox() {
    labelEl.remove(LABEL_EBOX);
    this.labelCount = 0;
  }

  selectChildBoxFlag = false;
  selectBoxFlag = false;
  resetEleFlagFalse() {
    this.selectBoxFlag = false;
  }
  resetEleFlagTrue() {
    this.selectBoxFlag = true;
  }
  resetChildEleFlagFalse() {
    this.selectChildBoxFlag = false;
  }
  resetChildEleFlagTrue() {
    this.selectChildBoxFlag = true;
  }
  boxData = {};
  childBoxData = {};
  /** @name 主控箱和分控箱点击事件 */
  async clickElectricBox(selected) {
    const box = selected.parent;
    this.resetLabelBox();
    if (box.name === 'electric-box') {
      this.resetChildEleFlagFalse();
      const data = await getMainBoxStatus({
        deviceName: this.climbPos.deviceName,
      });
      this.boxData = {
        ...data,
        floor: this.climbPos.floor,
        floorHeight: this.climbPos.floorHeight,
      };
      this.resetEleFlagTrue();
      this.setClimbPosCid(-2);
    } else {
      this.resetEleFlagFalse();
      const data = await getSubBoxStatus({
        deviceName: this.climbPos.deviceName,
        buildingId: this.climbPos.buildId,
        cid: box.userData.count,
      });
      if (data) {
        this.resetChildEleFlagTrue();
        this.setClimbPosCid(-2);
        this.childBoxData = {
          ...data,
          cid: padZero(box.userData.count),
          CID: box.userData.count,
          position: box.position,
        };
      } else {
        this.$message.warn('暂无分控箱信息！');
      }
    }
  }
  /** @name 主控箱预警或故障状态信息 */
  showMainEleStatus() {
    let item = {
      type: '',
      time: '',
      desc: '',
    };
    this.resetChildEleFlagFalse();
    this.resetEleFlagTrue();
    if (this.boxData.alarmMessage && this.boxData.alarmMessage.length) {
      this.setClimbPosCid(-1);
      item = getMessage(this.boxData.alarmMessage[0]);
      const hasList = this.boxData.alarmMessage.length > 1 ? true : false;
      labelEl.add(LABEL_EBOX, alertEle.electric(item, this.$style, hasList), {
        ...this.mainEle.position,
        y: this.mainEle.position.y + 0.1,
      });
    }
  }
  childStatus = 'warns';
  /** @name 分控箱预警或故障状态信息 */
  showChildEleStatus(status) {
    let item = {
      type: '',
      time: '',
      desc: '',
    };
    this.resetEleFlagFalse();
    this.resetChildEleFlagTrue();
    if (this.childBoxData[status] && this.childBoxData[status].length) {
      this.setClimbPosCid(this.childBoxData.CID);
      this.childStatus = status;
      item = getMessage(this.childBoxData[status][0]);
      const hasList = this.childBoxData[status].length > 1 ? true : false;
      labelEl.add(
        LABEL_EBOX,
        alertEle.electric(item, this.$style, hasList, 'childEleIcon'),
        {
          ...this.childBoxData.position,
          y: this.childBoxData.position.y + 0.1,
        },
      );
    }
  }
  labelCount = 0;
  handleLabel(event) {
    this.labelCount++;
    let ele = undefined;
    let array = [];
    if (event.target.id === 'electricIcon') {
      array = this.boxData.alarmMessage;
      ele = this.mainEle;
    } else if (event.target.id === 'childEleIcon') {
      array = this.childBoxData[this.childStatus];
      ele = this.childBoxData;
    }
    let message = array[this.labelCount];
    if (!message) {
      this.labelCount = 0;
      message = array[this.labelCount];
    }
    let item = getMessage(message);
    const hasList = array.length > 1 ? true : false;
    labelEl.add(
      LABEL_EBOX,
      alertEle.electric(item, this.$style, hasList, event.target.id),
      {
        ...ele.position,
        y: ele.position.y + 0.1,
      },
    );
  }
  idx = -1;
  onClickBuilding(event) {
    const intersects = raycasterFactory(event, this.camera).intersectObject(
      this.scence,
      true,
    );

    if (
      intersects.length &&
      intersects[0].instanceId >= 0 &&
      intersects[0].object.isInstancedMesh
    ) {
      const obj = intersects[0].object;
      if (this.idx !== -1) {
        this.resetColorLayer();
      }

      if (obj.name === 'floor') {
        /** @name 楼层 */
        this.clickFloor(intersects);
      } else if (obj.name === 'climb') {
        this.clearTimeForRecord();
        /** @name 爬架信息 */
        this.clickClimb(intersects);
      }
    } else {
      if (this.idx !== -1) {
        this.idx = -1;
      }
      this.clearTimeForRecord();
      this.clearTime();
      this.resetColorLayer();
      labelEl.remove(LABEL_V587);
    }
  }
  time = null;
  clearTime() {
    if (this.time) {
      clearInterval(this.time);
    }
  }
  /** @name 停止操作记录的定时器 */
  clearTimeForRecord() {
    this.selectBuildId = 'nf';
  }
  clockChange(data, flag) {
    if (flag) {
      let count = data[0];
      this.resetColorLayer();
      labelEl.remove(LABEL_V587);
      this.time = setInterval(() => {
        if (count > data[data.length - 1] - 1) {
          count = data[0] - 1;
        }
        this.resetColorLayer();

        const item = this.selectBuildArray[count];
        this.layerColorMesh.visible = true;
        this.layerColorMesh.position.set(item.x, item.y, item.z);

        labelEl.add(
          LABEL_V587,
          `楼层：${this.selectBuildArray[count].layer}`,
          {
            ...this.selectBuildArray[count],
            x: this.selectBuildArray[count].x + 6,
          },
          {
            className: `${this.$style.buildingLayer}`,
          },
        );

        count++;
      }, 5000);
    } else {
      this.clearTime();
    }
  }
  selectBuildId = '';
  selectBuildArray = [];
  formatArray(id) {
    const newArr = this.layerMesh.userData.array.filter(
      item => item.buildId === id,
    );
    this.selectBuildArray = newArr;
  }
  /** @name 楼层点击 */
  clickFloor(intersects) {
    const obj = intersects[0].object;
    let instanceId = intersects[0].instanceId;
    this.idx = instanceId;
    const layerItem = obj.userData.array[instanceId];
    this.layerColorMesh.visible = true;
    this.layerColorMesh.position.set(layerItem.x, layerItem.y, layerItem.z);

    this.clearTime();

    this.formatArray(layerItem.buildId);
    this.selectBuildId = layerItem.group;

    labelEl.add(
      LABEL_V587,
      `楼层：${layerItem.layer}`,
      {
        ...layerItem,
        x: layerItem.x + 6,
      },
      {
        className: `${this.$style.buildingLayer}`,
      },
    );
  }

  ambientLight = null;
  /** @name 给爬架内部添加环境光 */
  addClimbLight(item) {
    if (!this.ambientLight) {
      this.ambientLight = new THREE.AmbientLight(0xffffff, 1);
    }
    if (this.ambientLight && !this.ambientLight.visible) {
      this.ambientLight.visible = true;
    }
    this.ambientLight.position.set(item.x, item.y + 0.15, item.z);
    this.scence.add(this.ambientLight);
  }
  /** @name 移除爬架内的环境光 */
  removeClimbLight() {
    this.scence.remove(this.ambientLight);
    this.ambientLight = null;
  }
  /** @name 显示爬架内的环境光 */
  visibleClimbLightTrue() {
    if (!this.ambientLight.visible) {
      this.ambientLight.visible = true;
    }
  }
  /** @name 隐藏爬架内的环境光 */
  visibleClimbLightFalse() {
    if (this.ambientLight.visible) {
      this.ambientLight.visible = false;
    }
  }

  async clickClimb(intersects) {
    /** @name 爬架信息 */
    const obj = intersects[0].object;
    let instanceId = intersects[0].instanceId;
    const item = obj.userData.array[instanceId];

    this.linkClimb(item);
  }
  async linkClimb(item) {
    if (this.openFlag) {
      this.openFlag = false;
    }
    this.clearTime();
    this.resetColorLayer();
    this.ClimbVisable(item);
    this.GridVisable(item);

    labelEl.data.forEach(item => {
      item.elem.style.display = 'none';
    });

    const { devices, seatNumber } = await getBuildingInfo(item.buildId);
    this.climbPos = {
      ...item,
      deviceName: devices.length ? devices[0].deviceName : 0,
      devices: devices,
      cid: -2,
    };
    if (devices.length) {
      // 主控箱和分控箱的数量
      this.createElectric(item, devices.length, seatNumber);
    }

    // this.climbPos = {
    //   ...item,
    //   // deviceName: devices[0].deviceName,
    // };
    // console.log(getBuildingInfo);
    // this.createElectric(item, 3, 60);

    this.addClimbLight(this.climbPos);

    animateCamera(
      this.camera,
      this.controls,
      { x: item.x, y: item.y, z: item.z - 1 },
      { x: item.x, y: item.y, z: item.z },
      1200,
      async () => {
        document.removeEventListener('click', this.onClickBuilding);
        document.addEventListener('click', this.onClickCameraAndBox);
        this.climbMesh.visible = false;

        try {
          let distribution = await getMainBoxDistribution({
            buildingId: this.climbPos.buildId,
          });
          if (distribution) {
            getBoxDistribution(
              distribution,
              this.climbPos,
              this.electricPointArray,
            );
          }
          // eslint-disable-next-line no-empty
        } catch (error) {
        } finally {
          this.inClimb = true;
        }

        if (devices.length) {
          const data = await getMainBoxStatus({
            deviceName: this.climbPos.deviceName,
          });
          this.boxData = {
            ...data,
            floor: this.climbPos.floor,
            floorHeight: this.climbPos.floorHeight,
          };
          this.resetEleFlagTrue();
        }
      },
    );
  }
  /** @name 在楼栋间跳转 */
  toggleClimb(item) {
    if (this.climbPos && this.climbPos.buildId === item.buildId) {
      return false;
    }
    this.selectChildBoxFlag = false;
    this.linkClimb(item);
  }

  returnBack() {
    animateCamera(
      this.camera,
      this.controls,
      { x: 14, y: 22, z: 64 },
      { x: 0, y: 0, z: 0 },
      1200,
      () => {
        this.resetChildEleFlagFalse();
        this.resetEleFlagFalse();
        this.setClimbPosCid(-2);
        this.inClimb = false;
        this.climbMesh.visible = true;
        this.angle = 0;
        document.removeEventListener('click', this.onClickCameraAndBox);
        document.addEventListener('click', this.onClickBuilding);
        this.resetClimb();
        this.resetGrid();

        this.electricModelArray.forEach(v => {
          v.visible = false;
        });
        labelEl.data.forEach(item => {
          item.elem.style.display = 'block';
        });
        this.resetLabelBox();

        this.removeClimbLight();
      },
    );
  }
  climbPos = null;
  /**
   * @name 当前选中的电箱（包含主控箱和分控箱）
   * @description 主控箱cid = -1；分控箱分别是大于0的整数；-2表示没有对应的电箱
   */
  setClimbPosCid(id) {
    this.climbPos.cid = id;
  }
  returnClimb() {
    const po = this.climbPos;
    this.visibleClimbLightTrue();
    animateCamera(
      this.camera,
      this.controls,
      { x: po.x, y: po.y, z: po.z - 1 },
      { x: po.x, y: po.y, z: po.z },
      1200,
      () => {
        this.ClimbVisable(this.climbPos);
        this.GridVisable(this.climbPos);

        for (let item of this.cameraList) {
          item.visible = false;
        }

        this.electricModelArray.forEach(v => {
          v.visible = true;
        });
      },
    );
  }
  cameraList = [];
  // -3.5, 1.8, -0.8
  // 查看摄像头
  async returnCamera() {
    this.resetEleFlagFalse();
    this.resetChildEleFlagFalse();
    this.electricModelArray.forEach(v => {
      v.visible = false;
    });

    this.visibleClimbLightFalse();

    const res = this.cameraList.find(
      v => v.userData.buildId === this.climbPos.buildId,
    );
    if (!res) {
      const { records } = await getCameraList({
        buildId: this.climbPos.buildId,
        pageNum: 1,
        pageSize: 100,
      });

      const array = load.camera(this.cameraModel, records, this.climbPos);
      this.cameraList = array;
      array.forEach(v => {
        this.scence.add(v);
      });
    }

    const po = this.climbPos;
    animateCamera(
      this.camera,
      this.controls,
      { x: po.x - 3.5, y: po.y + 1.8, z: po.z - 1.8 },
      { x: po.x, y: po.y, z: po.z },
      1200,
      () => {
        this.resetClimb();

        for (let item of this.cameraList) {
          item.visible = true;
        }
      },
    );
  }
  angle = 0;
  // 角度来源：https://www.wjceo.com/blog/threejs2/2018-12-05/181.html
  angleArray = [3, 1.5, 0, -1.5];
  arrowLeft() {
    this.angle++;
    if (this.angle === 4) {
      this.angle = 0;
    }
    this.controls.setAngle(2, this.angleArray[this.angle]);
  }
  arrowRight() {
    this.angle--;
    if (this.angle === -1) {
      this.angle = 3;
    }
    this.controls.setAngle(2, this.angleArray[this.angle]);
  }

  degAngle = 0;
  get deg() {
    return `${-this.degAngle}deg`;
  }
  animate() {
    requestAnimationFrame(this.animate);

    this.renderElement = this.renderer.domElement;
    if (this.isDev) {
      this.status.update();
    }
    TWEEN.update();
    labelEl.updateLabels(this.camera, this.renderElement);
    this.controls.update();

    this.degAngle = THREE.Math.radToDeg(this.controls.getAzimuthalAngle());

    this.renderer.render(this.scence, this.camera);
  }
  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  mainBack() {
    this.resetEleFlagFalse();
    this.resetLabelBox();
  }
}
</script>

<style lang="less" module>
.buildingLayer {
  font-size: 0.16rem;
  color: white;
  position: absolute;
  background-color: #1e2640;
  width: auto;
  height: 0.28rem;
  padding: 0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.04rem;
}
.wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .labels {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.draw {
  position: fixed;
  top: 0;
  right: 0;
  width: 8.5rem;
  height: 100vh;
  transform: translateX(100%);
  background-color: rgba(30, 38, 64, 0.8);
  transition: transform 0.5s;
  z-index: 9999;
}
.drawOpen {
  transform: translateX(0);
}
.outIcon {
  background-color: rgb(98, 106, 132, 0.5);
  width: 0.2rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  transform: translateX(-100%);
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  position: absolute;
  bottom: 0.6rem;
  .iconStyle {
    transition: all 0.5s;
    font-size: 0.2rem;
    color: white;
  }
}
.rightIcon {
  transform: rotate(-180deg);
}
.btnList {
  position: absolute;
  left: 0.3rem;
  top: 0.3rem;
  display: flex;
  .iconStyle {
    font-size: 0.2rem;
    margin-right: 0.1rem;
  }
}
.btnItem {
  color: white;
  margin: 0.1rem;
  font-size: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  height: 0.6rem;
  width: 1.5rem;
  &:last-child {
    .iconStyle {
      margin-right: 0;
      font-size: 0.4rem;
    }
  }
  .btnText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.5rem;
  }
  .barHeight {
    height: 0.1rem;
  }
  .iconStyle1 {
    display: none;
  }
}
.btnItem-active {
  color: #05d3ff;
  .iconStyle1 {
    display: block;
    color: #05d3ff;
    margin-right: 0.02rem;
    font-size: 0.12rem;
  }
  .bar {
    width: 1.3rem;
    height: 0.01rem;
    background-color: #05d3ff;
  }
}
.three {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
.buildingInfo {
  position: absolute;
  top: 0;
  color: white;
  background: #2c5ca4;
  opacity: 0.82;
  width: 1.8rem;
  padding: 0.16rem 0 0.22rem 0.31rem;
  transform: translate(-50%, -100%);
  .title {
    text-align: center;
    font-size: 0.16rem;
    font-weight: 500;
    color: #fefefe;
    margin-left: -0.31rem;
  }
  .list {
    margin: 0.15rem 0;
    font-size: 0.14rem;
    &:last-child {
      margin-bottom: 0;
    }
    .text {
      font-weight: 400;
      color: #fefefe;
      opacity: 0.7;
    }
  }
  .border_corner {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 0.02rem solid #06caf7;
  }
  .border_corner_left_top {
    top: -2px;
    left: -2px;
    border-right: none;
    border-bottom: none;
  }
  .border_corner_right_top {
    top: -2px;
    right: -2px;
    border-left: none;
    border-bottom: none;
  }
  .border_corner_left_bottom {
    bottom: -2px;
    left: -2px;
    border-right: none;
    border-top: none;
  }
  .border_corner_right_bottom {
    bottom: -2px;
    right: -2px;
    border-left: none;
    border-top: none;
  }
}
.electricInfo {
  position: absolute;
  top: 0;
  color: white;
  background: #2c5ca4;
  width: 2rem;
  font-size: 0.12rem;
  padding: 0.16rem;
  transform: translate(-50%, -100%);
  .icon {
    font-size: 0.12rem;
    position: absolute;
    right: 0.1rem;
    top: 0.18rem;
    cursor: pointer;
  }
  .title {
    text-align: center;
  }
  .list {
    margin: 0.02rem 0;
    line-height: 0.16rem;
    &:last-child {
      margin-bottom: 0;
    }
    .text {
      color: #aaa;
    }
  }
}
.arrowBox {
  position: absolute;
  top: 0.03rem;
  right: 0.03rem;
  cursor: pointer;
  background-color: rgba(30, 38, 64, 0.5);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0.06rem;
  .arrowBoxLabel {
    padding: 0.04rem;
    border: 0.01rem solid rgba(30, 38, 64, 0.5);
    border-radius: 0.4rem;
    font-size: 0.14rem;
  }
  .arrowBoxLabel + .arrowBoxLabel {
    margin-left: 0.04rem;
  }
}
</style>
