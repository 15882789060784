<template>
  <div :class="$style.rect" @click.stop="rectClick">
    <svg
      v-if="showSvg"
      width="150"
      height="141"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <linearGradient id="v1" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:rgb(255, 0, 0);stop-opacity:1;" />
          <stop
            offset="100%"
            style="stop-color:rgb(255, 159, 0);stop-opacity:1;"
          />
        </linearGradient>
      </defs>
      <rect
        rx="16"
        ry="16"
        opacity="0.5"
        width="150"
        height="141"
        style="fill: rgba(24, 30, 51)"
      />
      <circle
        v-for="(item, idx) in pointArray"
        :key="idx"
        :cx="item.x"
        :cy="item.y"
        :r="item.r"
        :class="current && current.cid === item.cid ? $style.ani : ''"
        :stroke="fillModel(item)"
        :fill="fillModel(item)"
      />
    </svg>
    <div :class="$style.rectIcon" v-if="showSvg">
      <!-- <a-icon
        type="down"
        :class="$style.icon"
        :style="{
          transform: `rotate(${deg})`,
        }"
      /> -->
      <svg
        width="50"
        height="50"
        xmlns="http://www.w3.org/2000/svg"
        :style="{
          transform: `rotate(${deg})`,
        }"
      >
        <g>
          <path
            stroke="#05d3ff"
            fill="#05d3ff"
            stroke-width="1.5"
            d="m2.5,14.953125c27.462909,-27.031319 47.694605,0 47.502288,-0.579248l-47.502288,0.579248z"
          />
          <ellipse
            stroke="#05d3ff"
            fill="#05d3ff"
            stroke-width="1.5"
            cx="25.751862"
            cy="33.453125"
            rx="10.5"
            ry="10.5"
          />
        </g>
      </svg>
    </div>
    <div v-else :class="$style.rectBox">
      <span
        :class="$style.rectSpan"
        v-for="item in climbArray"
        :key="item.buildId"
        @click.stop="toggleClimb(item)"
      >
        <span
          :class="[
            $style.rectText,
            (current && current.buildId) === item.buildId ? $style.actived : '',
          ]"
        >
          {{ item.buildName.slice(0, 3) }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component()
export default class RectView extends Vue {
  @Prop({ type: Array, default: () => [] }) pointArray;
  @Prop({ type: Array, default: () => [] }) climbArray;
  @Prop({ type: String, default: '0deg' }) deg;
  @Prop({ type: Object, default: null }) current;
  @Prop({ type: Function, default: function() {} }) toggleClimb;
  showSvg = true;
  rectClick() {
    this.showSvg = !this.showSvg;
  }

  fillModel(item) {
    if (item.error && item.warn) {
      return 'url(#v1)';
    } else if (item.error) {
      return 'red';
    } else if (item.warn) {
      return 'orange';
    } else {
      return item.color;
    }
  }
}
</script>

<style lang="less" module>
@keyframes circleAni {
  0% {
    stroke-width: 5;
    stroke-opacity: 0.1;
  }
  25% {
    stroke-width: 9;
    stroke-opacity: 0.3;
  }
  50% {
    stroke-width: 5;
    stroke-opacity: 0.4;
  }
  75% {
    stroke-width: 5;
    stroke-opacity: 0.3;
  }
  100% {
    stroke-width: 5;
    stroke-opacity: 0;
  }
}
.rect {
  position: absolute;
  top: 0.52rem;
  right: 0.03rem;
  cursor: pointer;
  transform: rotate(180deg);
  width: 1.5rem;
  height: 1.41rem;
  background-color: rgba(24, 30, 51, 0.5);
  border-radius: 0.16rem;
  .ani {
    animation: circleAni 3s ease-out infinite;
  }
  .rectIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .icon {
      font-size: 0.4rem;
      color: #ffffff;
    }
  }
  .rectBox {
    transform: rotate(-180deg);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0.16rem;
    font-size: 0.14rem;
    color: #ffffff;
    .rectSpan {
      width: 0.334rem;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 0.12rem;
      .rectText {
        width: 0.2rem;
        padding: 0 0.02rem;
        display: inline-flex;
        justify-content: center;
        background-color: rgba(66, 67, 54, 0.6);
        &.actived {
          background-color: #05d3ff;
        }
      }
    }
  }
}
</style>
