<template>
  <div>
    <div :class="$style.select">
      <a-select
        :dropdownClassName="$style.selectOption"
        style="width: 1.2rem"
        v-model="selectDevice"
        @change="selectedChange"
      >
        <a-select-option
          v-for="item in selectList"
          :value="item.id"
          :key="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <div :class="$style.logoDiv">
      <div :class="$style.logoName">{{ projectName }}</div>
    </div>
    <div :class="$style.info">
      <div :class="[$style.card, $style.score]">
        <div>
          <span :class="$style.title">报告评分</span>
          <x-icon
            v-if="this.reportId"
            :class="$style.code"
            type="tc-icon-qrcode"
            @mouseenter="showCode = true"
            @mouseleave="showCode = false"
          />
        </div>
        <div v-if="!showCode" :class="$style.fen">
          <div>
            <span :class="$style.number">{{ baseInfo.score }}</span>
            <span :class="$style.text">分</span>
          </div>
        </div>
        <div v-else :class="$style.showCode">
          <x-qrcode
            :class="$style.image"
            :style="qrStyle"
            :src="src"
            :option="option"
          />
        </div>
      </div>
      <div :class="$style.card">
        <div :class="$style.currentLayer">
          <div>
            <div :class="$style.title">
              <span>当前层数</span>
              <a-icon
                :class="$style.play"
                v-show="showIcon"
                @click="playClick"
                type="pause-circle"
              />
              <a-icon
                :class="$style.play"
                v-show="hideIcon"
                @click="playClick"
                type="play-circle"
              />
            </div>
          </div>
          <div :class="$style.layer">
            <a-icon
              v-show="hideIcon"
              type="left"
              @click.stop="changeLayer(1)"
              :class="[
                currentLayer === floorArr[0]
                  ? $style.disabledIcon
                  : $style.clickIcon,
              ]"
            />
            <span :class="$style.layerinner">{{ baseInfo.currentLayer }}</span>
            <a-icon
              v-show="hideIcon"
              type="right"
              @click.stop="changeLayer(2)"
              :class="[
                currentLayer === floorArr[floorArrLength - 1]
                  ? $style.disabledIcon
                  : $style.clickIcon,
              ]"
            />
          </div>
        </div>
        <div :class="$style.upDown">
          <div :class="$style.up">
            <x-icon
              :class="[$style.iconStyle, $style.rotate90]"
              type="tc-icon-arrow-right-filled"
            />
            <div :class="$style.text">
              <div>提升</div>
              <div>{{ baseInfo.riseTypeCount.riseNumber }}次</div>
            </div>
          </div>
          <div :class="$style.up">
            <x-icon
              :class="[$style.iconStyle, $style.ratate180]"
              type="tc-icon-arrow-right-filled"
            />
            <div :class="$style.text">
              <div>
                下降
              </div>
              <div>{{ baseInfo.riseTypeCount.dropNumber }}次</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.card">
        <div :class="$style.currentLayer">
          <div :class="$style.title">操作时间</div>
          <div :class="$style.layer">
            {{ formateTime(baseInfo.operationDate) }}
          </div>
        </div>
        <div :class="$style.upDown">
          <div :class="$style.up">
            <x-icon :class="$style.iconStyle" type="tc-icon-hook-loose" />
            <div :class="$style.text">
              <div>
                松钩
              </div>
              <div>{{ baseInfo.riseTypeCount.looseHookNumber }}次</div>
            </div>
          </div>
          <div :class="$style.up">
            <x-icon :class="$style.iconStyle" type="tc-icon-hook-tight" />
            <div :class="$style.text">
              <div>
                紧钩
              </div>
              <div>{{ baseInfo.riseTypeCount.tightHookNumber }}次</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.charts">
      <div :class="$style.chartsPie">
        <div :class="$style.name">分控箱状态</div>
        <div :class="$style.barDiv">
          <x-icon :class="$style.iconStyle1" type="tc-icon-status" />
          <div :class="$style.bar"></div>
        </div>
        <div v-if="showPieChart" style="height: 100%">
          <div ref="pieCharts" style="width:100%;height: 100%" />
        </div>
        <div v-else style="height: 100%">
          <no-data-chart :imgStyle="{ left: '40%', top: '40%' }" />
        </div>
      </div>
      <div :class="$style.chartsBar">
        <div :class="$style.name">
          分控箱事件
          <span style="color: #aaa">（预警/故障详情）</span>
        </div>
        <div :class="$style.barDiv">
          <x-icon :class="$style.iconStyle1" type="tc-icon-status" />
          <div :class="$style.bar"></div>
        </div>
        <div v-if="showBarChart" style="height: 100%">
          <div ref="barCharts" style="width:100%;height: 100%"></div>
        </div>
        <div v-else style="height: 100%">
          <no-data-chart :imgStyle="{ left: '40%', top: '40%' }" />
        </div>
      </div>
    </div>
    <div :class="$style.range">
      <div :class="$style.rangeItem">
        <div style="font-size: 0.18rem">
          倾斜范围
        </div>
        <div :class="$style.rangeBar">
          <div :class="$style.normal" :style="{ width: stance[2] }"></div>
          <div
            :class="$style.normal"
            :style="{
              backgroundColor: '#95f204',
              width: stance[1],
              left: stance[0],
              position: 'absolute',
              top: 0,
            }"
          >
            <div :class="$style.tooltip">
              <div :class="$style.arrow"></div>
              <div :class="$style.tips">
                <span>倾斜范围：{{ stance[4] }}毫米</span>
              </div>
            </div>
          </div>
          <div :class="$style.tips60">
            0mm
          </div>
          <div :class="$style.tips60" :style="{ left: stance[2] }">
            60毫米
          </div>
          <div :class="$style.tipsMax">{{ stance[3] }}毫米</div>
        </div>
      </div>
    </div>
    <div :class="[$style.range, $style.gravity]">
      <div :class="$style.rangeItem">
        <div style="font-size: 0.18rem">
          载荷范围
        </div>
        <div :class="$style.rangeBar">
          <div
            :class="$style.normal"
            :style="{ width: gravity[2], backgroundColor: '#80ffff' }"
          ></div>
          <div
            :class="$style.normal"
            :style="{
              backgroundColor: '#00bfbf',
              width: gravity[1],
              left: gravity[0],
              position: 'absolute',
              top: 0,
            }"
          >
            <div :class="$style.tooltip">
              <div :class="$style.arrow"></div>
              <div :class="$style.tips">
                <span>载荷范围：{{ gravity[3] }}吨</span>
              </div>
            </div>
          </div>
          <div :class="$style.tips60">
            0吨
          </div>
          <div :class="$style.tips60" :style="{ left: gravity[2] }">
            6吨
          </div>
          <div :class="$style.tipsMax">7.5吨</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import xQrcode from '@/components/x-qrcode';
import dayjs from 'dayjs';
import { createModuleUrl } from '@/utils';
import {
  getBuildingRecordByLock as getBuildingRecord,
  getBuildingInfo,
  reportGenerate,
} from '@/services/things/building';
@Component({
  components: {
    xQrcode,
  },
})
export default class OperationRecord extends Vue {
  @Prop({ type: Array, default: () => [] }) buildingInfo;
  @Prop({ type: String, default: '' }) buildingID;

  isPlay = true;
  showCode = false;
  playClick() {
    this.isPlay = !this.isPlay;
    clearInterval(this.timmer);
    if (this.isPlay) {
      this.createTimmer();
    } else {
      this.$emit('cnm', this.floorArr, false);
    }
  }
  selectList = [];
  selectDevice = 0;
  src = '';
  @Watch('src', { immediate: true })
  changeSrc(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.src = newVal;
    }
  }
  qrStyle = {
    width: '130px',
    height: '130px',
  };
  option = {
    margin: 5,
    color: {
      background: '#242845',
      foreground: '#4586a8',
    },
  };
  baseInfo = {
    riseTypeCount: {
      dropNumber: 0,
      looseHookNumber: 0,
      riseNumber: 9,
      tightHookNumber: 0,
    },
  };
  get showIcon() {
    return this.isPlay && this.floorArr.length > 1;
  }
  get hideIcon() {
    return !this.isPlay && this.floorArr.length > 1;
  }
  selectedChange(v) {
    this.getList(v, this.currentLayer);
  }
  changeLayer(v) {
    if (v === 1) {
      if (this.currentLayer > this.floorArr[0]) {
        this.currentLayer--;
        this.getList(this.selectDevice, this.currentLayer);
      }
    } else {
      const len = this.floorArr.length;
      if (this.currentLayer < this.floorArr[len - 1]) {
        this.currentLayer++;
        this.getList(this.selectDevice, this.currentLayer);
      }
    }
  }
  projectName = '';
  floorArr = [];
  floorArrLength = 0;
  currentLayer = 0;
  clickBuildingID = '';
  @Watch('buildingID', { immediate: true })
  changeID(newVal, oldVal) {
    if (newVal && newVal !== 'nf') {
      if (newVal !== oldVal) {
        this.clickBuildingID = this.buildingInfo[newVal - 1]?.pkBuildingId;
        this.getBuildingInfos();
      }
    } else {
      this.clickBuildingID = this.buildingInfo[0]?.pkBuildingId;
      this.getBuildingInfos();
    }
  }
  timmer = null;
  beforeDestroy() {
    if (this.timmer) {
      clearInterval(this.timmer);
    }
  }
  isFirst = true;
  async getBuildingInfos() {
    this.floorArr = [];
    try {
      const buildingID = this.clickBuildingID;
      // const res = await getBuildingInfo('1352c7935e39c1c0413d19ab9b8b9d43');
      const res = await getBuildingInfo(buildingID);
      this.projectName = res.projectName;
      this.selectList = res.alias.map(v => {
        return {
          id: v,
          name: `${v}号电箱`,
        };
      });
      if (res.firstLayer === res.currentLayer) {
        this.floorArr.push(res.firstLayer);
        this.currentLayer = res.firstLayer;
      } else {
        for (let i = res.firstLayer + 1; i <= res.currentLayer; i++) {
          this.floorArr.push(i);
        }
        this.currentLayer = res.firstLayer + 1;
      }
      this.floorArrLength = this.floorArr.length;
      this.selectDevice = res.alias[0];
      // 楼栋大于 1 开启定时
      if (this.isFirst) {
        if (this.floorArrLength > 1) {
          this.createTimmer();
        } else {
          await this.getList(res.alias[0], this.currentLayer);
        }
      } else {
        if (this.timmer) {
          clearInterval(this.timmer);
          this.isPlay = false;
        }
        await this.getList(res.alias[0], this.currentLayer);
      }
      this.isFirst = false;
    } catch {
      return false;
    }
  }
  createTimmer() {
    const fArr = this.floorArr;
    this.isPlay = true;
    this.getList(this.selectDevice, fArr[0]);
    let count = 1;
    this.$emit('cnm', fArr, true);
    this.timmer = setInterval(() => {
      if (count === fArr.length) {
        count = 0;
      }
      this.getList(this.selectDevice, fArr[count]);
      count++;
    }, 5000);
  }
  stance = [];
  gravity = [];
  reportId = '';
  showPieChart = false;
  showBarChart = false;
  async getList(deviceNum, currentLayer) {
    const params = {
      buildingId: this.clickBuildingID,
      // buildingId: '1352c7935e39c1c0413d19ab9b8b9d43',
      alias: deviceNum,
      layer: currentLayer,
    };
    try {
      const res = await getBuildingRecord(params);
      this.baseInfo = {
        score: res.score,
        currentLayer: `${res.currentLayer}层/${res.currentHeight}米`,
        operationDate: res.operationDate,
        riseTypeCount: res.riseTypeCount,
      };
      this.currentLayer = res.currentLayer;
      this.reportId = res.reportId;
      if (this.reportId) {
        this.src = await this.getReport();
      }
      this.stance = this.getStance(res.tiltRange);
      this.gravity = this.getGravity(res.loadRange);
      if (res.subStatusCount.sum > 0) {
        this.showPieChart = true;
        this.$nextTick(() => {
          this.initPieChart(res.subStatusCount);
        });
      } else {
        this.showPieChart = false;
      }
      if (res.subEventCount.length > 0) {
        this.showBarChart = true;
        this.$nextTick(() => {
          this.initBarChart(res.subEventCount);
        });
      } else {
        this.showBarChart = false;
      }
    } catch {
      return false;
    }
  }
  async getReport() {
    const linkParams = {
      finallyEffectiveDate: 'ONE',
      password: '',
      idxReportRelation: this.reportId,
    };
    const { idxReportRelation } = await reportGenerate(linkParams);
    return `${createModuleUrl(
      'm',
    )}/#/pages/iot/shareInputPassword/index?id=${idxReportRelation}&type=REPORT`;
  }
  formateTime(str) {
    return str ? dayjs(str).format('YYYY-MM-DD') : '';
  }
  colorArr = ['#70ad47', '#ed7d31', '#ff0000'];
  pieChart = null;
  initPieChart(data) {
    const chart = this.$refs.pieCharts;
    if (!chart) return;
    if (this.pieChart) {
      this.pieChart.dispose();
    }
    this.pieChart = echarts.init(chart);
    const seriesData = [
      { value: data.normalNumber, name: '正常' },
      { value: data.warnNumber, name: '预警' },
      { value: data.errorNumber, name: '故障' },
    ];
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}台({d}%)',
        backgroundColor: 'rgba(30, 38, 64, 0.5)',
        borderColor: 'white',
        textStyle: {
          color: 'white',
        },
      },
      color: this.colorArr,
      legend: {
        bottom: '20%',
        left: '10%',
        textStyle: {
          color: 'white',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      series: [
        {
          type: 'pie',
          center: ['40%', '40%'],
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: 'rgba(30, 38, 64, 0.8)',
            borderWidth: 1,
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
        },
      ],
    };
    this.pieChart.setOption(option);
  }
  get dataZoom() {
    return [
      {
        type: 'slider',
        show: true,
        start: 100,
        end: 100,
        zoomLock: true, // 不能缩放
        brushSelect: false, // 不能鼠标点击刷选
        yAxisIndex: [0],
        width: 8,
        height: 250, // 组件高度
        right: 30, // 左边的距离
        bottom: 50, // 右边的距离
        fillerColor: '#516289',
        borderColor: 'rgba(30, 38, 64, 0.5)',
        backgroundColor: 'rgba(30, 38, 64, 0.9)',
        handleStyle: {
          color: 'rgba(0, 0, 0, 0)',
          opacity: 0,
        },
        showDataShadow: false, // 是否显示数据阴影 默认auto
        showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        handleIcon:
          'M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
        filterMode: 'filter',
      },
    ];
  }
  barChart = null;
  initBarChart(data) {
    const chart = this.$refs.barCharts;
    if (!chart) return;
    if (this.barChart) {
      this.barChart.dispose();
    }
    this.barChart = echarts.init(chart);
    const yData = [];
    const warnArr = [];
    const errorArray = [];
    data.forEach(v => {
      yData.push(`${v.cid}号`);
      warnArr.push(v.warnNumber);
      errorArray.push(v.errorNumber);
    });
    const zoom = this.dataZoom;
    zoom[0].start = 100;
    zoom[0].end = 100;
    if (yData.length > 7) {
      zoom[0].show = true;
      const num = Math.round((7 / yData.length) * 10000) / 100.0;
      zoom[0].end = 100 - Math.floor(num);
    } else {
      zoom[0].show = false;
      zoom[0].start = 0;
    }
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
        backgroundColor: 'rgba(30, 38, 64, 0.5)',
        textStyle: {
          color: 'white',
        },
        formatter(params) {
          const name = params[0].name;
          const arr = params.map(v => v.data);
          let str = `<div>${name}</div>`;
          if (arr[0]) {
            str += `<div>预警：${arr[0]}次</div>`;
          }
          if (arr[1]) {
            str += `<div>故障：${arr[1]}次</div>`;
          }
          return str;
        },
      },
      color: ['#ed7d31', '#ff0000'],
      legend: {
        show: false,
      },
      xAxis: {
        type: 'value',
        axisTick: { show: false },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      dataZoom: zoom,
      yAxis: {
        type: 'category',
        data: yData,
        axisLine: {
          show: false,
          lineStyle: {
            color: 'white',
          },
        },
        axisTick: { show: false },
      },
      series: [
        {
          name: '预警',
          type: 'bar',
          barWidth: 25,
          stack: 'total',
          data: warnArr,
        },
        {
          name: '故障',
          type: 'bar',
          barWidth: 25,
          stack: 'total',
          data: errorArray,
        },
      ],
    };
    this.barChart.setOption(option);
  }

  getStance({ min, max } = { min: 0, max: 0 }) {
    const Max = max > 60 ? max + 30 : 80;
    const widthNormal = Math.ceil(((60 / Max) * 10000) / 100);
    const widthStance = Math.ceil((((max - min) / Max) * 10000) / 100);
    const left = Math.ceil(((min / Max) * 10000) / 100);
    return [
      `${left}%`,
      `${widthStance}%`,
      `${widthNormal}%`,
      Max,
      `${min}-${max}`,
    ];
  }
  getGravity({ min, max } = { min: 0, max: 0 }) {
    const widthNormal = Math.ceil(((6 / 7.5) * 10000) / 100);
    const widthGravity = Math.ceil((((max - min) / 7.5) * 10000) / 100);
    const left = Math.ceil(((min / 7.5) * 10000) / 100);
    return [`${left}%`, `${widthGravity}%`, `${widthNormal}%`, `${min}-${max}`];
  }
}
</script>

<style lang="less" module>
.logoDiv {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  .logoName {
    width: 7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #05d3ff;
    font-size: 0.3rem;
    font-weight: 500;
    text-align: center;
  }
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem 0;
  .card {
    width: 2.5rem;
    height: 1.8rem;
    color: white;
  }
  .score {
    text-align: center;
    position: relative;
    .title {
      font-size: 0.2rem;
      margin-right: 0.05rem;
      color: #05d3ff;
    }
    .code {
      font-size: 0.2rem;
      cursor: pointer;
      color: #05d3ff;
    }
    .fen {
      margin-top: 0.3rem;
      color: #05d3ff;
      .number {
        font-size: 0.55rem;
      }
      .text {
        font-size: 0.18rem;
      }
    }
    .showCode {
      width: 1.3rem;
      height: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0.5rem;
      left: 0.65rem;
      z-index: 9999;
    }
  }
  .currentLayer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      height: 0.3rem;
      font-size: 0.2rem;
      color: #05d3ff;
    }
    .layer {
      height: 0.5rem;
      margin: 0.05rem 0 0.1rem;
      font-size: 0.18rem;
      color: #05d3ff;
    }
    .clickIcon {
      cursor: pointer;
      color: white;
    }
    .disabledIcon {
      cursor: not-allowed;
      color: #aaa;
    }
    .layerinner {
      margin: 0 0.1rem;
      color: #05d3ff;
    }
    .play {
      font-size: 0.2rem;
      margin-left: 0.05rem;
      cursor: pointer;
    }
  }
  .upDown {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .up {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 1rem;
      .iconStyle {
        font-size: 0.3rem;
        margin-bottom: 0.05rem;
        color: #05d3ff;
      }
      .text {
        font-size: 0.18rem;
        text-align: center;
      }
      .rotate90 {
        transform: rotate(-90deg);
      }
      .ratate180 {
        transform: rotate(90deg);
      }
    }
  }
}
.charts {
  margin-left: 0.8rem;
  display: flex;
  justify-content: space-around;
  .chartsPie {
    height: 3.5rem;
    width: 3rem;
  }
  .barDiv {
    display: flex;
    align-items: center;
    .iconStyle1 {
      color: #05d3ff;
      margin-right: 0.02rem;
      font-size: 0.12rem;
    }
    .bar {
      width: 1rem;
      height: 0.01rem;
      background-color: #05d3ff;
    }
  }
  .chartsBar {
    height: 3.5rem;
    width: 5rem;
  }
  .name {
    font-size: 0.18rem;
    color: white;
    margin-left: 0.1rem;
  }
}
.range {
  margin-left: 0.8rem;
  width: 7rem;
  .rangeItem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    .rangeBar {
      width: 6rem;
      border-radius: 0.04rem;
      background-color: #aaaaaa;
      position: relative;
      .normal {
        background-color: #caf982;
        height: 0.2rem;
        border-top-left-radius: 0.04rem;
        border-bottom-left-radius: 0.04rem;
        .tooltip {
          position: absolute;
          top: 0.3rem;
          left: 50%;
          transform: translateX(-50%);
          .tips {
            min-width: 0.5rem;
            height: 0.35rem;
            background-color: rgb(60, 72, 111, 0.5);
            font-size: 14px;
            padding: 0.06rem 0.08rem;
            border-radius: 0.04rem;
            white-space: nowrap;
            text-align: left;
            display: flex;
            align-items: center;
          }
          .arrow {
            position: absolute;
            display: block;
            width: 0.13rem;
            height: 0.13rem;
            overflow: hidden;
            background: 0 0;
            left: 50%;
            transform: translateX(-50%);
            top: -0.13rem;
            &::before {
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              width: 0.07rem;
              height: 0.07rem;
              margin: auto;
              background-color: #aaaaaa;
              content: '';
              pointer-events: auto;
              box-shadow: -0.03rem -0.03rem 0.07rem rgb(0, 0, 0, 0.07);
              transform: translateY(0.0654rem) rotate(45deg);
              position: absolute;
            }
          }
        }
      }
      .tips60 {
        font-size: 0.18rem;
        position: absolute;
        top: -100%;
        transform: translateX(-50%);
      }
      .tipsMax {
        font-size: 0.16rem;
        position: absolute;
        top: -100%;
        left: 100%;
        transform: translateX(-50%);
        width: 0.6rem;
      }
    }
  }
}
.gravity {
  margin-top: 0.65rem;
}
.select {
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
}
:global {
  .ant-select-selection {
    color: white;
    border-color: white;
    &:hover {
      border-color: white;
    }
  }
  .ant-select-open .ant-select-selection {
    border-color: white;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:active,
  .ant-select-selection:focus {
    border-color: white;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  }
  .ant-select-dropdown-menu-item-selected {
    color: white;
  }
  .ant-select-dropdown {
    background-color: rgba(30, 38, 64, 0.5);
    color: white;
    z-index: 9999;
  }
  .ant-select-dropdown-menu-item {
    color: white;
    &:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: rgba(30, 38, 64, 0.5);
    }
    &-selected {
      background-color: rgba(30, 38, 64, 0.5);
    }
    &-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: rgba(30, 38, 64, 0.5);
    }
  }
  .ant-select-arrow {
    color: white;
  }
}
</style>
