<template>
  <div :class="[$style.wrap]">
    <a-carousel arrows :class="$style.box" ref="carouselRef">
      <div
        slot="prevArrow"
        class="custom-slick-arrow"
        :class="$style['custom-slick-arrow-left']"
      >
        <a-icon type="left-circle" />
      </div>
      <div
        slot="nextArrow"
        class="custom-slick-arrow"
        :class="$style['custom-slick-arrow-right']"
      >
        <a-icon type="right-circle" />
      </div>
      <div
        :class="$style.imageBox"
        v-for="(obj, idx) in list"
        :key="idx + 'obj'"
      >
        <div
          v-for="item in obj"
          :key="item.pkCameraId"
          @click="toMonitor(item)"
          :class="$style.objItem"
        >
          <div
            v-if="item.url"
            :class="[
              $style.monitor_li,
              item.online ? '' : $style.outline,
              cameraId === item.pkCameraId ? $style.current_monitor : '',
            ]"
          >
            <span>{{ item.cameraName }}</span>
          </div>
          <div v-else></div>
        </div>
      </div>
    </a-carousel>
    <!-- <div
      :class="[
        $style.monitor_li,
        item.online ? '' : $style.outline,
        cameraId === item.pkCameraId ? $style.current_monitor : '',
      ]"
      v-for="item in ordinary"
      :key="item.pkCameraId"
      @click="toMonitor(item)"
    >
      <span>{{ item.cameraName }}</span>
      <a-icon
        v-if="hasEdit"
        @click.stop="handleEditCamera(item)"
        :class="$style.cameraIcon"
        type="edit"
      />
    </div> -->
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import createModal from '@/views/iot/screen/components/message/modal.js';
import EditCamera from './edit-camera.vue';
import { monitorModule } from '@/enum/store.js';

@Component()
export default class monitorSelect extends Vue {
  @Prop({ type: Array }) monitorList;
  @Prop({ type: Boolean, default: true }) hasEdit;

  get ordinary() {
    return this.monitorList.filter(e => e.cameraType === 'ORDINARY');
  }

  get list() {
    let result = [];
    const rec = [];
    let length = this.ordinary.length;
    for (let i = 0; i < length; i++) {
      if ((i + 1) % 4 === 0) {
        result.push(this.ordinary[i]);
        rec.push(result);
        result = [];
      } else {
        result.push(this.ordinary[i]);
        if (length - 1 === i) {
          const abs = 4 - result.length;
          for (let j = 0; j < abs; j++) {
            result.push({
              pkCameraId: j,
              url: '',
            });
          }
          rec.push(result);
        }
      }
    }
    return rec;
  }

  @monitorModule.Mutation updateVideo;
  @monitorModule.Mutation updateShowShot;
  @monitorModule.Mutation updateCameraId;
  @monitorModule.State cameraId;
  async toMonitor(monitorInfo) {
    this.currentItem = monitorInfo;
    this.updateShowShot(false);
    this.updateCameraId(monitorInfo.pkCameraId);
    if (!monitorInfo.online) {
      this.updateVideo({
        url: '',
        token: '',
      });
      return;
    }
    this.updateVideo({
      url: monitorInfo.url,
      token: monitorInfo.token,
    });
    this.$emit('monitor-change');
  }

  currentItem = undefined;
  handleEditCamera(item) {
    this.currentItem = item;
    createModal(
      () => (
        <EditCamera
          ref={'EditRef'}
          data={item}
          change={name => this.changeCurrentName(name)}
        />
      ),
      {
        title: '编辑摄像头',
        className: this.$style.warningModal,
        closeTxt: '取消',
        okTxt: '确定',
        ok: () => {
          this.$refs.EditRef && this.$refs.EditRef.updateCameraNameApi();
        },
      },
    );
  }
  changeCurrentName(name) {
    this.currentItem.cameraName = name;
  }
}
</script>
<style lang="less" module>
@import './monitor.less';
.monitor_list {
  width: 10%;
  overflow-y: auto;
  background: @boxBg;
  background-repeat: no-repeat;
  background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
  // border: 1px solid @lightLineColor;
  box-shadow: @boxshadow;
  .monitor_type {
    font-size: 0.18rem;
    margin-top: 0.3rem;
    margin-left: calc((100% - 1.3rem) / 2);
  }
}

.box {
  height: 0.6rem;
  .custom-slick-arrow-left {
    left: 0.1rem;
    z-index: 1;
  }
  .custom-slick-arrow-right {
    right: 0.1rem;
  }
}
.wrap {
  height: 0.6rem;
  .imageBox {
    height: 0.6rem;
    display: inline-flex !important;
    align-items: center;
    .objItem {
      flex: 1;
    }

    .monitor_li {
      flex: 1;
      margin: 0 0.2rem;
      height: 0.46rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.14rem;
      white-space: nowrap;
      background: url('~@/views/iot/screen/assets/images/monitor/monitor-bg.png')
        center center no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      color: rgb(119, 119, 119);

      &.outline {
        opacity: 0.6;
      }
      &.current_monitor {
        color: #fff;
      }
      &:not(.outline):hover {
        color: #fff;
      }
    }
  }
  :global {
    .ant-carousel .slick-slide {
      text-align: center;
      height: 0.6rem;
      line-height: 1;
      background-color: rgba(8, 20, 47, 0.5);
      overflow: hidden;
    }

    .ant-carousel .custom-slick-arrow {
      width: 0.25rem;
      height: 0.25rem;
      font-size: 0.25rem;
      color: #fff;
      background-color: rgba(31, 45, 61, 0.11);
      opacity: 0.3;
    }
    .ant-carousel .custom-slick-arrow:before {
      display: none;
    }
    .ant-carousel .custom-slick-arrow:hover {
      opacity: 0.5;
      color: #fff !important;
    }

    .ant-carousel .slick-slide h3 {
      color: #fff;
    }
  }
}
</style>
