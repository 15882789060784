<template>
  <div :class="$style.deviceInfo">
    <div :class="[$style.flex, $style.totalLoad]">
      <div :class="[$style.iconBack, $style.deviceHeight]">
        <x-icon
          :class="[$style.iconStyle, $style.blue]"
          type="tc-icon-sub-cabinet"
        />
        <div :class="$style.mainBox">
          <div>分控箱{{ detail.cid }}</div>
        </div>
      </div>
    </div>
    <div :class="[$style.flex, $style.person]">
      <a-avatar :size="55" :src="person.avatar" :class="$style.avatar" />
      <span>现场管理：{{ person.memberName ? person.memberName : '无' }}</span>
    </div>
    <div :class="[$style.flex, $style.totalLoad]">
      <div :class="$style.iconBack">
        <x-icon
          :class="[$style.iconStyle, $style.blue]"
          type="tc-icon-weight"
        />
      </div>
      <div>{{ detail.load }}吨</div>
    </div>
    <div :class="[$style.flex, $style.totalLoad]">
      <div :class="$style.iconBack">
        <x-icon
          :class="[$style.iconStyle, $style.blue]"
          type="tc-icon-electric-current"
        />
      </div>
      <div>{{ detail.leftAndRightTilt }}毫米</div>
    </div>
    <div :class="[$style.flex, $style.totalLoad]">
      <div :class="$style.iconBack">
        <x-icon
          :class="[$style.iconStyle, $style.blue]"
          type="tc-icon-stack-filled"
        />
      </div>
      <div>{{ detail.beforeAndAfterTilt }}毫米</div>
    </div>
    <!-- <div :class="[$style.flex, $style.totalLoad]">
      <div :class="[$style.iconBack, $style.deviceHeight]">
        <x-icon
          :class="[$style.iconStyle, $style.blue]"
          type="tc-icon-sub-cabinet"
        />
        <div style="color: white; margin-left: 0.1rem">分控箱</div>
      </div>
    </div> -->
    <div
      :class="[$style.flex, $style.totalLoad]"
      @click.stop="$emit('status-click', 'errors')"
    >
      <div :class="$style.iconBack">
        <x-icon
          :class="[$style.iconStyle, $style.red]"
          type="tc-icon-caution"
        />
      </div>
      <div>{{ detail.errorCount }}个</div>
    </div>
    <div
      :class="[$style.flex, $style.totalLoad]"
      @click.stop="$emit('status-click', 'warns')"
    >
      <div :class="$style.iconBack">
        <x-icon
          :class="[$style.iconStyle, $style.yellow]"
          type="tc-icon-warning-triangle"
        />
      </div>
      <div>{{ detail.warnCount }}个</div>
    </div>
    <div :class="[$style.flex, $style.back]" @click="$emit('back')">
      <span>返回楼栋视图</span>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InfoView extends Vue {
  @Prop({ type: Object, default: () => {} }) detail;

  get person() {
    if (this.detail.members && this.detail.members.length) {
      return this.detail.members[0];
    } else {
      return {};
    }
  }

  get status() {
    if (this.detail.alarmMessage && this.detail.alarmMessage.length) {
      const hasError = this.detail.alarmMessage.findIndex(
        v => v.eventType === 'ERROR',
      );
      if (hasError !== -1) {
        return '故障';
      } else {
        return '预警';
      }
    } else {
      return '正常';
    }
  }
}
</script>

<style lang="less" module>
.deviceInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1.5rem;
  width: 100%;
  background-color: rgba(30, 38, 64, 0.5);
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.16rem;
    cursor: default;
    .iconBack {
      width: 0.55rem;
      height: 0.55rem;
      border-radius: 50%;
      background-color: rgba(30, 38, 64, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.05rem;
      .iconStyle {
        font-size: 0.24rem;
      }
      .blue {
        color: #05d3ff;
      }
      .red {
        color: red;
      }
      .yellow {
        color: #ffeb45;
      }
    }
    .deviceHeight {
      height: 1.5rem;
      width: 100%;
      background-color: rgba(30, 38, 64, 0.8);
      margin-bottom: 0;
      border-radius: 0;
    }
    .mainBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0.1rem;
      font-size: 0.14rem;
    }
  }
  .person {
    font-size: 0.18rem;
    width: 2rem;
    .avatar {
      margin-bottom: 0.05rem;
    }
  }
  .totalLoad {
    width: 2rem;
  }
  .back {
    margin-left: 0.8rem;
    cursor: pointer;
  }
}
</style>
